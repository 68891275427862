import React, { useCallback } from "react";
import Particles from "react-tsparticles";
import { loadFull } from "tsparticles";
import "./App.css";
import particlesOptions from "./particles.json";
import { GiWolfHead } from "react-icons/gi";
import { IconContext } from "react-icons";

function App() {
  const particlesInit = useCallback((main) => {
    loadFull(main);
  }, []);

  return (
    <div className="App">
      <Particles options={particlesOptions} init={particlesInit} />
      <div className="Card">
        <div className="CardTitle">
          <IconContext.Provider value={{ className: "Icon", size: "90px" }}>
            <div>
              <GiWolfHead />
            </div>
          </IconContext.Provider>
          <div>VAI, LOBO GUARÁ!!! #SEMCOMP</div>
        </div>
        <div className="CardBody">
          <p>Hey, I'm Guaradikson :)</p>

          <p>
            I’m a Brazilian Lobo Guará who likes to make cool and pretty stuff.
          </p>

          <p>
            If you're interested in seeing what kind of things I can do, check
            out my <a href="https://github.com/diksown">GitHub</a>,{" "}
            <a href="https://www.linkedin.com/in/diksown/">LinkedIn</a> and{" "}
            <a href="https://drive.google.com/file/d/1X1jBj0hRpKjfQzi-3c3VitK7ewcdV5H7/view">
              Resume
            </a>
            .
          </p>

          <p>Spoiler: Right now, I’m open to work! Woof!</p>
        </div>
      </div>
    </div>
  );
}

export default App;
